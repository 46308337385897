.todo {
  align-self: center;
  color: white;
}

.todo .crossText {
  text-decoration: line-through;
  color: #40514E;
}

.todo input {
  background: darkslategray;
  border: none;
  border-radius: 10px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 5px;
  color: white;
  min-width: 400px;
  margin-top: 40px;
  padding: 8px;
  font-size: 1.3rem;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.todo input::placeholder {
  color: white;
  font-size: 1.3rem;
}


.todo .add-btn {
  padding: 5px;
  border-radius: 10%;
  color: black;
  font-size: 1.2rem;
  background-color: #11999E;
  border: none;
  margin-left: 10px;
  cursor: pointer;
  border: 3px solid black;
}

.todo .add-btn:hover {
  padding: 5px;
  font-size: 1.7rem;
}

.todo ul {
  margin-top: 30px;
  font-size: 1.5rem;
}

.todo ul li {
  list-style: none;
  margin-bottom: 4px;
  border: 2px solid black;
  border-radius: 10px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 5px;
  margin-left: -100px;
  background-color: #E4F9F5;
  font-family: " Cambria, Cochin, Georgia, Times, 'Times New Roman', serif";
  color: black;
}

.todo ul li .delete {
  margin-top: 4px;
  margin-bottom: 4px;
  float: right;
  padding: 4px 10px 4px 10px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  background-color: red;
  color: white;
}

.todo ul li .completed {
  margin-top: 4px;
  margin-bottom: 4px;
  float: right;
  padding: 4px 10px 4px 10px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  background-color: green;
  margin-left: 10px;
  color: white;
}