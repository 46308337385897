.App {
  display: flex;
  flex-flow: column nowrap;
  margin-top: 50px;
}

.App .title {
  font-size: 5rem;
  padding: 0px 20px 0px 20px;
  background-color: #11999E;
  border-radius: 10px;
  border: 2px solid black;
  align-self: center;
  color: #1b1b1b;
  font-family: "Cambria, Cochin, Georgia, Times, 'Times New Roman', serif";
}